import {
  ArrowUpTrayIcon,
  CalendarDaysIcon,
  ChatBubbleBottomCenterTextIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { fetchAllLeave, updateLeaveStatus } from "../../services/leave.service";
import {
  ILeaveRecords,
  IUpdateLeaveStatus,
  Leave,
} from "../../types/interfaces/ILeaveRecords.interface";
import { formatDateSlash, formatDateSpace } from "../../utils/dateFromatter";
import { scrollToTop } from "../../utils/tableScroller";
import ReasonPopOver from "../../widgets/ReasonPopOver";
import ApproveRejectModal from "../Modals/ApproveRejectModal";

interface Props {
  getCurrentDayLeaveInfo: () => void;
  openPopoverId: string | null;
  setOpenPopoverId: (id: string | null) => void;
}

const LeaveMangemnetTable: React.FC<Props> = ({
  getCurrentDayLeaveInfo,
  openPopoverId,
  setOpenPopoverId,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [leaveRequests, setLeaveRequests] = useState<ILeaveRecords>({
    Leaves: [],
  });
  const [comments, setComments] = useState("");
  const [reqAgain, setReqAgain] = useState(false);
  const [actionToPerform, setActionToPerform] = useState("");
  const [selectedRecord, setSelectedRecord] = useState<Leave>();
  const [dateRange, setDateRange] = useState<Date[]>([]);
  const [leaveFrom, leaveTo] = dateRange?.length > 0 ? dateRange : [];
  const [scrollOnDate, setScrollOnDate] = useState(false);
  let timeoutId: NodeJS.Timeout | null = null;
  let scrollToTopRef = useRef<HTMLTableSectionElement>(null);

  const getAllLeave = async (
    startDate?: string,
    endDate?: string,
    searchQuery?: string
  ) => {
    const res = await fetchAllLeave(startDate, endDate, searchQuery);
    if (res?.res) {
      setLeaveRequests(res?.res as ILeaveRecords);
    }
  };

  const handleLeaveAction = async (
    leaveReqData: Leave,
    paymentStatus: string
  ) => {
    const reqBody: IUpdateLeaveStatus = {
      leaveID: leaveReqData?.leaveRequest?.LeaveID,
      userID: leaveReqData?.leaveRequest?.ID,
      status: actionToPerform,
      comment: comments,
      paymentStatus: paymentStatus,
    };
    if (!comments) {
      toast.error("Please add some comments");
      return;
    }

    const res = await updateLeaveStatus(reqBody);

    if (res?.res) {
      setReqAgain(!reqAgain);
      if (actionToPerform === "rejected") {
        toast.success("Leave Request Rejected Successfully");
      } else {
        toast.success("Leave Request Approved Successfully");
      }
      getCurrentDayLeaveInfo();
      closeModal();
    }
    closeModal();
  };

  const handleExportCSV = async () => {
    const res: any = await fetchAllLeave();

    if (res?.res) {
      const leaves = res.res.Leaves || [];

      const exportToXLSX = (
        filename: string,
        data: any[],
        sheetName: string
      ) => {
        const workBook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(data);
        worksheet["!cols"] = [
          { wch: 35 },
          { wch: 20 },
          { wch: 20 },
          { wch: 20 },
          { wch: 30 },
          { wch: 30 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
          { wch: 15 },
        ];
        XLSX.utils.book_append_sheet(workBook, worksheet, sheetName);
        XLSX.writeFile(workBook, `${filename}.xlsx`);
      };

      const processRows = (data: any[]) => {
        return data.map((item, index) => {
          const {
            ID,
            userName,
            LeaveType,
            Reason,
            Status,
            Comment,
            DateFrom,
            DateTo,
            NumberOfDays,
            CreatedAt,
          } = item.leaveRequest;

          return {
            Name: item.userName,
            LeaveType: LeaveType,
            Reason: Reason || "-",
            Status: Status,
            Comment: Comment || "-",
            DateFrom: formatDate(DateFrom),
            DateTo: formatDate(DateTo),
            NumberOfDays: String(NumberOfDays),
            CreatedAt: formatDate(CreatedAt),
          };
        });
      };

      const formatDate = (dateString: string) => {
        if (!dateString) return "";
        let date;
        if (dateString.includes("T")) {
          date = new Date(dateString);
        } else {
          const [day, month, year] = dateString.split("/").map(Number);
          date = new Date(year, month - 1, day);
        }
        return date.toLocaleDateString("en-GB");
      };

      const saveAsXlsx = () => {
        const formattedData = processRows(leaves);
        exportToXLSX("LeavesData", formattedData, "Leave Data");
      };

      saveAsXlsx();
    } else {
      console.error("Error fetching leave requests:", res.error);
    }
  };

  const handleComments = (comments: string) => {
    setComments(comments);
  };
  useEffect(() => {
    if (!dateRange[1]) return;
    setReqAgain(!reqAgain);
    setScrollOnDate(!scrollOnDate);
  }, [dateRange]);

  useEffect(() => {
    if (searchQuery) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        if (dateRange?.length > 0) {
          getAllLeave(
            formatDateSlash(leaveFrom),
            formatDateSlash(leaveTo),
            searchQuery
          );
        } else getAllLeave(undefined, undefined, searchQuery);
      }, 1000);
    } else if (dateRange?.length > 0) {
      getAllLeave(formatDateSlash(leaveFrom), formatDateSlash(leaveTo));
    } else {
      getAllLeave();
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [reqAgain, searchQuery]);

  const openModal = (event: any, record: Leave) => {
    setIsModalOpen(true);
    setActionToPerform(event?.target?.name);
    setSelectedRecord(record);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setComments("");
  };

  const togglePopover = (record: Leave) => {
    if (openPopoverId === record.leaveRequest.LeaveID) {
      setOpenPopoverId(null);
    } else {
      setOpenPopoverId(record.leaveRequest.LeaveID);
    }
    setSelectedRecord(record);
  };

  const handleClear = () => {
    setDateRange([]);
    setReqAgain(!reqAgain);
  };

  useEffect(() => {
    scrollToTop(scrollToTopRef);
  }, [searchQuery, scrollOnDate]);

  const handleKeyDown = (e: any) => {
    e.preventDefault();
  };

  return (
    <>
      <div className="py-5">
        <div className="relative flex md:flex-row flex-col md:items-center mb-3 md:mb-6 gap-3">
          <input
            className="cursor-text w-full md:max-w-[310px] py-[10px] ps-10 pe-4 border border-[#D6D6D6] bg-[#ffffff] rounded-lg focus:outline-none placeholder:text-sm"
            type="search"
            placeholder="Search"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e?.target?.value)}
          />
          <span className="absolute top-3 left-3">
            <MagnifyingGlassIcon className="w-5 h-5 text-[#858585]" />
          </span>
          <div className="relative mb-3 md:mb-0 flex items-center gap-1 sm:gap-2 z-10">
            <ReactDatePicker
              className="cursor-pointer rounded-lg md:min-w-[230px] max-w-[230px] bg-[#ffffff] border border-[#D6D6D6] text-sm py-3 px-5 placeholder:text-[#333] placeholder:font-medium"
              onChange={(date) => {
                setDateRange(date as Date[]);
              }}
              startDate={leaveFrom}
              endDate={leaveTo}
              placeholderText="Select Range"
              selectsRange={true}
              dateFormat="dd/MM/yyyy"
              preventOpenOnFocus
              onKeyDown={(e) => handleKeyDown(e)}
            />
            {leaveTo ? (
              <button
                className="absolute top-3 right-4 items-center"
                onClick={handleClear}
              >
                <XMarkIcon className="text-black w-5 h-5  right-4" />
              </button>
            ) : (
              <span className="absolute top-3 right-4 pointer-events-none">
                <CalendarDaysIcon className="w-5 h-5 text-[#333]" />
              </span>
            )}
          </div>
          <button
            className="btnExport text-xs min-w-[85px] font-semibold text-[#378EB5] flex items-center w-[107px] ml-auto gap-1 bg-[rgb(55,142,181)] text-white py-[9px] px-[10px]"
            onClick={() => handleExportCSV()}
          >
            <ArrowUpTrayIcon className="w-4 h-4" />
            Export CSV
          </button>
        </div>

        {leaveRequests?.Leaves?.length > 0 ? (
          <>
            <div
              className="Employees md:px-0 overflow-scroll overflow-x-hidden h-[calc(100vh-325px)]"
              ref={scrollToTopRef}
            >
              <table className="capitalize leaveMange w-full p-2">
                <thead className="sticky top-0">
                  <tr>
                    <th></th>
                    <th>Employee Name</th>
                    <th>Leave Type</th>
                    <th>Leave from</th>
                    <th>Leave to</th>
                    <th>Applied On</th>
                    <th>Reason</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {leaveRequests?.Leaves?.map((req, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td data-cell="Employee Name">{req?.userName}</td>
                      <td data-cell="Leave Type">
                        {req?.leaveRequest?.LeaveType}
                      </td>
                      <td data-cell="Leave Date">
                        {formatDateSpace(req?.leaveRequest?.DateFrom)}
                      </td>
                      <td data-cell="Leave date">
                        {formatDateSpace(req?.leaveRequest?.DateTo)}
                      </td>{" "}
                      <td data-cell="Applied On">
                        {formatDateSpace(
                          moment(req?.leaveRequest?.CreatedAt).format(
                            "DD/MM/YYYY"
                          )
                        )}
                      </td>
                      <td data-cell="Reason">
                        <div className="relative">
                          <button
                            onClick={() => togglePopover(req)}
                            className={`text-sm text-[#378EB5] font-semibold ${
                              openPopoverId &&
                              openPopoverId.length > 0 &&
                              (openPopoverId === req.leaveRequest.LeaveID
                                ? ""
                                : "cursor-default")
                            }`}
                          >
                            <ChatBubbleBottomCenterTextIcon className="text-#378EB5 w-5 h-5" />
                          </button>

                          {openPopoverId === req.leaveRequest.LeaveID && (
                            <ReasonPopOver
                              reason={req.leaveRequest.Reason as string}
                              onClose={() => setOpenPopoverId(null)} // Pass onClose function
                            />
                          )}
                        </div>
                      </td>
                      <td>
                        <div className="flex justify-end items-center gap-1">
                          {req?.leaveRequest?.Status === "pending" ? (
                            <>
                              <button
                                className="btnApprove"
                                name="accepted"
                                onClick={(event) => openModal(event, req)}
                              >
                                Approve
                              </button>
                              <button
                                className="btnReject"
                                name="rejected"
                                onClick={(event) => openModal(event, req)}
                              >
                                Reject
                              </button>
                            </>
                          ) : req?.leaveRequest?.Status === "accepted" ? (
                            <span className="bg-[#23CDAF] text-white font-semibold text-[10px] rounded-[4px] px-2 py-0.5 uppercase">
                              Approved
                            </span>
                          ) : (
                            <span className="bg-[#333333] text-white font-semibold text-[10px] rounded-[4px] px-2 py-0.5 uppercase">
                              Rejected
                            </span>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <ApproveRejectModal
              isOpen={isModalOpen}
              onClose={closeModal}
              comments={comments}
              handleComments={handleComments}
              recordData={selectedRecord}
              actionToPerform={actionToPerform}
              sendRequest={handleLeaveAction}
              isLeaveType={true}
            />
          </>
        ) : (
          <div className="text-center flex flex-col justify-center mt-16">
            <span className="text-center flex justify-center mb-3">
              <img src="/images/no-data.png" alt="no have data" />
            </span>
            <p className="text-sm font-normal text-[#5C5C5C]">
              No Leave Request Available.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default LeaveMangemnetTable;
